.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bootstrap-logo {
  width: 72px;
  height: 72px;

}


#sign-in-form {
  max-width: 330px;
}

.form-control:focus {
  z-index: 5;
}

h2 .dark {
  color: white;
}

.map_thumb {
  width: 50px;

}

.white-content {
  background: #e7e7e7;
  color: rgb(54, 51, 51);
}

.white-content .card {
  
  background: #c7b8b8;
  color: rgb(61, 56, 56);
}
.card {
  
  background: #615a5a;
  color: rgb(255, 255, 255);
}





